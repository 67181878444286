import * as React from 'react'
import styled from 'styled-components'

import { COLORS, illustrations } from '@ulule/owl-kit-components/dist/next.esm'

const Wrapper = styled.div<{ inverted?: boolean }>`
  flex: 0 0 auto;
  width: auto;
  height: auto;
  background-color: ${({ inverted = false }) => (inverted ? COLORS.BRAND_ACCENT_GREEN : COLORS.PRIMARY_WHITE)};
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 20px 10px;
`

const IllustrationName = styled.span<{ inverted?: boolean }>`
  font-size: 12px;
  font-weight: 700;
  color: ${({ inverted = false }) => (inverted ? COLORS.PRIMARY_WHITE : COLORS.PRIMARY_BLACK)};
  margin-top: 16px;
`

type IllustrationBlockProps = {
  collection?: illustrations.Collection
  illustration: string
  inverted?: boolean
  noBackground?: boolean
}

export function IllustrationBlock({
  collection = 'twoToned',
  illustration,
  inverted = false,
  noBackground = false,
}: IllustrationBlockProps): React.ReactElement<IllustrationBlockProps> {
  if (!collection) {
    throw 'please provide a collection'
  }

  const Illustration = (illustrations as any)[collection][illustration]
  return (
    <Wrapper inverted={inverted}>
      <Illustration collection={collection} inverted={inverted} noBackground={noBackground} size={128} />
      <IllustrationName inverted={inverted}>{illustration}</IllustrationName>
    </Wrapper>
  )
}
