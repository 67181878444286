import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from '../../../layouts/Layout';
import { IllustrationBlock } from '../../../components/IllustrationBlock';
import { Playground } from '../../../components/Playground';
import { SEO } from '../../../components/SEO';
import { illustrations } from '@ulule/owl-kit-components/dist/next.esm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Identity > Iconography > Illustrations" mdxType="SEO" />
    <h1>{`Illustrations`}</h1>
    <h2>{`Usage`}</h2>
    <p>{`These illustrations are available through the `}<inlineCode parentName="p">{`illustrations`}</inlineCode>{` namespace, and can be used as follows:`}</p>
    <Playground scope={{
      illustrations
    }} mdxType="Playground">{`
  <illustrations.twoToned.Hourglass size={48} />
`}</Playground>
    <h2>{`Collections`}</h2>
    <h3>{`Two-toned`}</h3>
    <h4>{`Medium • Regular color`}</h4>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  <IllustrationBlock illustration="BookWithQuestion" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Coaching" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Coins" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="CrackedEgg" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="DeliveryMethods" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="EnvelopeWithHeart" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="HeartWithConfetti" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Hourglass" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Okpal" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="PaperPlane" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Share" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SpeechBubbles" mdxType="IllustrationBlock" />
    </div>
    <h4>{`Medium • Alternative color`}</h4>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  <IllustrationBlock illustration="BookWithQuestion" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Coaching" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Coins" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="CrackedEgg" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="DeliveryMethods" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="EnvelopeWithHeart" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="HeartWithConfetti" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Hourglass" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Okpal" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="PaperPlane" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Share" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SpeechBubbles" inverted mdxType="IllustrationBlock" />
    </div>
    <h4>{`Large Regular color`}</h4>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  <IllustrationBlock illustration="AllOrNothing" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="DiscoverProjects" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="IdeaToAction" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SecurePayment" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SubmitProject" mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Partners" mdxType="IllustrationBlock" />
    </div>
    <h4>{`Large • Alternative color`}</h4>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  <IllustrationBlock illustration="AllOrNothing" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="DiscoverProjects" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="IdeaToAction" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SecurePayment" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SubmitProject" inverted mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Partners" inverted mdxType="IllustrationBlock" />
    </div>
    <h4>{`Alternative color × No background`}</h4>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-10px'
    }}>
  <IllustrationBlock illustration="BookWithQuestion" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Coaching" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Coins" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="CrackedEgg" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="DeliveryMethods" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="EnvelopeWithHeart" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="HeartWithConfetti" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Hourglass" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Okpal" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="PaperPlane" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Share" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SpeechBubbles" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="AllOrNothing" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="DiscoverProjects" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="IdeaToAction" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SecurePayment" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="SubmitProject" inverted noBackground mdxType="IllustrationBlock" />
  <IllustrationBlock illustration="Partners" inverted noBackground mdxType="IllustrationBlock" />
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      